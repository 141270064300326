import * as React from 'react';

import Layout from 'components/organisms/Layout';
import SEO from 'components/atoms/Seo';
import ProjectListing from 'components/organisms/ProjectListing';

const Index = () => (
  <Layout>
    <SEO />
    <ProjectListing />
  </Layout>
);

export default Index;
