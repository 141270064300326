/** @jsx jsx */
import { Box } from '@theme-ui/components';
import Vimeo from '@u-wave/react-vimeo';
import { Link } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
// import useHover from 'hooks/useHover';
import placeholderImg from 'images/placeholder.png';
import { Fragment, useEffect, useRef, useState } from 'react';
// import { useNetworkStatus } from 'react-adaptive-hooks/network';
import { useInView } from 'react-intersection-observer';
import { jsx, Text } from 'theme-ui';
import { IProject } from 'types/HighScream.types';

interface Props {
  onLoad: (videoId: string) => void;
  placeholder?: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  staticPlaceholder?: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
}

function ProjectPreview({
  id,
  title,
  titleVideoId,
  slug,
  onLoad,
  // placeholder,
  staticPlaceholder,
}: Props & IProject) {
  const hoverRef = useRef(null);
  // const hoverActive = useHover(hoverRef);
  const [playVideo, togglePlayVideo] = useState(false);
  const [loadVideo, setLoadVideo] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const { ref, inView } = useInView();
  // const { effectiveConnectionType } = useNetworkStatus();
  // const isSlowConnection = effectiveConnectionType !== '4g';

  /**
   * Only load/play videos that are visible
   */
  useEffect(() => {
    if (inView && !loadVideo) {
      setLoadVideo(true);
    }
    togglePlayVideo(inView);
  }, [inView]);

  // console.log({ hoverActive });

  return (
    <Link
      ref={hoverRef}
      to={`projects/${slug}`}
      key={id}
      sx={{
        width: '100%',
        position: 'relative',
        overflow: 'hidden',
        display: 'block',
        textDecoration: 'none',
        height: 0,
        pt: '50%',

        '@media screen and (min-aspect-ratio: 16/9)': {
          height: '70vh',
          pt: 0,
        },
      }}
    >
      <Box
        ref={ref}
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 2,
          background: loadVideo ? 'rgba(0, 0, 0, 0.5)' : null,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',

          '&:before': {
            content: '""',
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundImage: `radial-gradient(circle, rgba(0,0,0,0.5) 0%, rgba(255,255,255,0) 100%);`,
            transition: 'all 300ms ease-out',
            opacity: 0,
            zIndex: 3,
          },

          '&:hover:before': {
            opacity: 1,
          },
        }}
      >
        <Text
          sx={{
            fontSize: [28, 28, 40, 40],
            fontWeight: 'body',
            textTransform: 'uppercase',
            position: 'relative',
            zIndex: 3,
            color: '#FFF',
            textAlign: 'center',
          }}
        >
          {title}
        </Text>
      </Box>
      {titleVideoId && (
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            top: '50%',
            transform: 'translate3d(0, -50%, 0)',
            backgroundImage: `url(${placeholderImg})`,
            backgroundRepeat: 'repeat',
            minHeight: '100%',
            zIndex: 1,

            // img: {
            //   pb: [2, 2, 3, 3],
            // },

            '.gatsby-image-wrapper-constrained': {
              display: 'block',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              minHeight: '100%',
            },
          }}
        >
          {staticPlaceholder && (
            <GatsbyImage
              image={staticPlaceholder.childImageSharp.gatsbyImageData}
              alt=""
              loading="eager"
            />
          )}
          {/* {!isSlowConnection && placeholder && (
            <GatsbyImage
              image={placeholder.childImageSharp.gatsbyImageData}
              alt=""
            />
          )} */}
          {loadVideo && (
            <Fragment>
              <Vimeo
                video={titleVideoId}
                controls={false}
                muted
                loop
                responsive
                autoplay
                autopause={false}
                paused={!playVideo}
                onError={(error) => console.log({ error })}
                onProgress={(progress) => {
                  if (progress.seconds > 0 && initialLoad) {
                    setInitialLoad(false);
                    onLoad(titleVideoId);
                  }
                }}
              />
            </Fragment>
          )}
        </Box>
      )}
    </Link>
  );
}

export default ProjectPreview;
