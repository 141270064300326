import { Box } from '@theme-ui/components';
import ProjectPreview from 'components/molecules/ProjectPreview';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { Fade } from 'react-awesome-reveal';
import { IProject } from 'types/HighScream.types';

function ProjectListing() {
  // const [videoLoadState, setVideoLoadState] = useState<string[]>([]);
  const [projects, setProjects] = useState<IProject[] | null>(null);

  const {
    allProjectsYaml: { nodes: projectsData },
    placeholders: { nodes: placeholdersData },
    staticPlaceholders: { nodes: staticPlaceholdersData },
  } = useStaticQuery(graphql`
    query ProjectListingQuery {
      allProjectsYaml(sort: { fields: order, order: DESC }) {
        nodes {
          id
          title
          titleVideoId
          slug
        }
      }
      placeholders: allFile(
        sort: { fields: name }
        filter: {
          name: { eq: "placeholder" }
          relativeDirectory: { regex: "/projects/" }
        }
      ) {
        nodes {
          id
          publicURL
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
          relativeDirectory
        }
      }
      staticPlaceholders: allFile(
        sort: { fields: name }
        filter: {
          name: { eq: "Still_Placeholder" }
          extension: { eq: "jpg" }
          relativeDirectory: { regex: "/projects/" }
        }
      ) {
        nodes {
          id
          publicURL
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
          relativeDirectory
        }
      }
    }
  `);

  useEffect(() => {
    if (!projects) {
      setProjects(
        projectsData.map((project: IProject) => ({
          ...project,
          placeholder: placeholdersData.find(
            ({ relativeDirectory }: { relativeDirectory: string }) =>
              relativeDirectory === `projects/${project.slug}`
          ),
          staticPlaceholder: staticPlaceholdersData.find(
            ({ relativeDirectory }: { relativeDirectory: string }) =>
              relativeDirectory === `projects/${project.slug}`
          ),
        }))
      );
    }
  }, [projectsData, placeholdersData]);

  if (!projects) return null;

  return (
    <Box>
      <Fade triggerOnce>
        {projects.map((project: IProject, index: number) => (
          <ProjectPreview key={project.id} 
            onLoad={
              (videoId) => null
              // setVideoLoadState((prev) => [...prev, videoId])
            }
            {...project}
          />
        ))}
      </Fade>
    </Box>
  );
}

export default ProjectListing;
